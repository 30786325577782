import React from "react";

import Layout from "../components/layout";

const NotFoundPage = (props) => {
  return (
    <Layout {...props}>
      <div className="relative bg-black">
        <div className="relative text-center">
          <p className="text-red-900 relative font-emphasis text-[20rem] py-20 opacity-50">4 0 4</p>
          <div className="text-yellow-200 text-4xl font-bold absolute top-[20rem] left-10 right-10">
            <p className="m-auto">
              PAGE NOT FOUND!
            </p>
            <p
              className="text-yellow-100 text-xl italic">
              Apologies! Looks like this page may have been removed or not created yet.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

NotFoundPage.propTypes = {};

export default NotFoundPage;
